export function UpdateIncomingCalls(data) {
    let incomingCallsValue = document.querySelector('.incoming-calls__value');

    if (incomingCallsValue) {
        incomingCallsValue.innerHTML = data.incomingCalls;
    }
}

export function UpdateMissedCalls(data) {
    let incomingCallsValue = document.querySelector('.missed-calls__value');

    if (incomingCallsValue) {
        incomingCallsValue.innerHTML = data.missedCalls;
    }
}
