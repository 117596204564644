import { UpdateIncomingCalls } from "./UpdateCallsValues";

export function UpdateAnsweredManagers(data) {
    let answeredManager = document.querySelector('.managers-item[data-id="' + data.managerId + '"]');

    if (answeredManager) {
        answeredManager.dataset.callId = data.callId;
        answeredManager.classList.add('__active');

        UpdateIncomingCalls(data);
    }
}

export function UpdateOutgoingManagers(data) {
    let answeredManager = document.querySelector('.managers-item[data-id="' + data.managerId + '"]');

    if (answeredManager) {
        answeredManager.dataset.callId = data.callId;
        answeredManager.classList.add('__active');
    }
}

export function UpdateManagersCallsValues (data) {
    for (let manager of data.callsValues) {
        let managerItem = document.querySelector('.managers-item[data-id="' + manager.managerId + '"]');

        managerItem.dataset.callId = data.callId;
        managerItem.classList.remove('__active');
        managerItem.querySelector('.dashboard-item__value').innerHTML =
            manager.incomingCalls + ' / ' + manager.outgoingCalls;
    }
}
